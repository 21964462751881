<template>
    <div>
        <div
        v-if="isOpen"
            tabindex="0"
            @keydown.esc="closeModal"
            class="w-100 center increaseZI"
            :class="isOpen && 'jquery-modal blocker current'"
        >
            <div class="w-50-l w-90 pv2 center box-border" style="background: white">
                <div class="flex justify-between pa3 box-border-bottom">
                    <div class="f3 b">Edit Budget</div>
                    <div>
                        <span class="pointer">
                            <img src="../assets/images/Close-icon.svg" alt="" @click.prevent="closeModal" />
                        </span>
                    </div>
                </div>
                <div class="f3 pv3 pl3 createLineBudgetHeader">Provide the information of the Budget that you want to add</div>
                <form class="entity-form pa3 center" @submit.prevent="onSubmit" enctype="multipart/form-data">
                    <div class="">
                        <div class="pv2" style="border-width: 2px">
                            <div class="pt2 w-100 pr2-l">
                                <div class="pv2 font-w1">
                                    <label for="budgetTitle">Budget Title</label>
                                </div>
                                <input class="w-100" v-model="state.budgetTitle" required type="text" id="budgetTitle" />
                            </div>
                            <div class="pt2 w-100 pr2-l">
                                <div class="pv2 font-w1">
                                    <label for="budgetLine">Budget Line</label>
                                </div>
                                <select class="w-100" required type="text" id="budgetLine">
                                    <option name="Asset">Asset</option>
                                    <option name="Liability">Liability</option>
                                    <option name="Income">Income</option>
                                    <option name="Expenses">Expenses</option>
                                    <option name="Equity">Equity</option>
                                </select>
                            </div>
                            <div class="pt2 w-100 pr2-l">
                                <div class="pv2 font-w1">
                                    <label for="startPeriod">Start Period</label>
                                </div>
                                <input required type="date" v-model="state.startPeriod" id="startPeriod" />
                            </div>
                            <div class="pt2 w-100 pr2-l">
                                <div class="pv2 font-w1">
                                    <label for="endPeriod">End Period</label>
                                </div>
                                <input required type="date" v-model="state.endPeriod" id="endPeriod" />
                            </div>

                            <div class="mt3 box-border-bottom"></div>
                            <div class="flex flex-wrap pt3" style="gap: 1rem; justify-content: flex-end">
                                <button class="mt3 btn2" @click.prevent="closeModal">Cancel</button>
                                <button class="mt3" type="submit">Save</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
import { ref, reactive, toRefs } from 'vue'
export default {
    name: 'EditPnLBudget',
    props: {
        isOpen: Boolean,
    },
    emits: ['closeModal'],
    setup(props, { emit }) {
        const initialState = () => ({
            budgetTitle: '',
            budgetLine: '',
            startPeriod: '',
            endPeriod: '',
            amount: '',
        })

        const { isOpen } = toRefs(props)

        const closeModal = () => {
            emit('closeModal')
        }

        const state = reactive(initialState())

        return {
            state,
            isOpen,
            closeModal,
        }
    },
}
</script>
<style scoped>
.createLineBudgetHeader {
    color: rgba(85, 89, 110, 0.75);
    font-size: 12px;
    font-weight: 600;
    text-align: left;
}
</style>
