<template>
    <budget-wrapper>
        <template v-slot:child>
            <div class="w-90 center mt4">
                <div class="flex" style="justify-content: flex-end">
                  <router-link :to="{name: 'CreatePnLBudget'}" >
                    <button class="flex items-center gap8">
                        <span><img :src="require('@/assets/images/plus.svg')" /></span><span>Create Budget</span>
                    </button>
                  </router-link>
                </div>
                <div class="margin24">
                    <div class="overflow-auto scrollmenu">
                        <table class="f6 w-100" cellspacing="0">
                            <thead>
                                <tr>
                                    <th class="py-8 billPad manageHeader pl2 fw6 tl p9 pr3">Budget Line</th>
                                    <th class="py-8 billPad manageHeader pl3 fw6 tl p9 pr3">Name</th>
                                    <th class="py-8 billPad manageHeader pl3 fw6 tl p9 pr3">Start Date</th>
                                    <th class="py-8 billPad manageHeader pl3 fw6 tl p9 pr3">End Date</th>
                                    <th class="py-8 billPad manageHeader pl3 fw6 tl p9 pr3">Status</th>
                                    <th class="py-8 billPad manageHeader pl3 fw6 tl p9 pr3">Total Budget</th>
                                    <th class="py-8 billPad manageHeader pl3 fw6 tl p9 pr3">Actual Budget</th>
                                    <th class="py-8 billPad manageHeader pl3 fw6 tl p9 pr3">Created On</th>

                                    <th class="py-8 billPad manageHeader pl3 fw6 tl p9 pr3">Action</th>
                                </tr>
                            </thead>
                            <tbody class="lh-copy">
                                <tr v-for="item in budgetData" :key="item.id">
                                    <td class="pv3 pr3 pl2">{{ item.budgetLine }}</td>
                                    <td class="pv3 pr3 pl3">{{ item.name }}</td>
                                    <td class="pv3 pr3 pl3">{{ item.start_date }}</td>
                                    <td class="pv3 pr3 pl3">{{ item.end_date }}</td>
                                    <td>
                                        <div :class="['pv2', 'pl3', pillClass(item.status)]">{{ item.status }}</div>
                                    </td>
                                    <td class="pv3 pr3 pl3">{{ item.total_budget }}</td>
                                    <td class="pv3 pr3 pl3">{{ item.actual_budget }}</td>
                                    <td class="pv3 pr3 pl3">{{ item.created_on }}</td>

                                    <!-- <td class="pv2 pl3 tc"><img :src="require('@/assets/images/action.svg')" /></td> -->
                                    <td class="tc">
                                        <div class="dropdown tc" style="position: relative">
                                            <a
                                                class="dropdown-togglex"
                                                href="#"
                                                role="button"
                                                id="dropdownMenuLink"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                            >
                                                <font-awesome-icon icon="fa-solid fa-ellipsis-vertical" class="fa-2x" />
                                            </a>

                                            <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink" style="cursor: pointer;">
                                                <li>
                                                    <router-link class="flex items-center gap8" :to="{ name: 'PnLView' }"
                                                        ><span><img :src="require('@/assets/images/eye.svg')" /></span>
                                                        <span>View</span></router-link
                                                    >
                                                </li>
                                                <li class="flex items-center gap8">
                                                    <span><img :src="require('@/assets/images/delete.svg')" /></span
                                                    ><span> Delete</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </template>
    </budget-wrapper>
</template>
<script>
import { ref } from 'vue'
import AppWrapper from '@/layout/AppWrapper.vue'
import BudgetWrapper from './widgets/BudgetWrapper.vue'
import CreatePnLBudget from '@/components/CreatePnLBudget.vue'
import EditPnLBudget from '@/components/EditPnLBudget.vue'
export default {
    name: 'PnLBudget',
    components: { AppWrapper, BudgetWrapper, CreatePnLBudget, EditPnLBudget},
    setup() {

        const budgetData = ref([
            {
                id: 1,
                budgetLine: 'Asset',
                name: 'Maintenance of truck',
                start_date: 'Nov 23, 2023',
                end_date: 'Nov 23, 2023',
                status: 'completed',
                total_budget: 'NGN10000000',
                actual_budget: 'NGN10000000',
                created_on: 'Nov 24, 2023',
                percentage_achieved: '25%',
            },
            {
                id: 2,
                budgetLine: 'Asset',
                name: 'Maintenance of truck',
                start_date: 'Nov 23, 2023',
                end_date: 'Nov 23, 2023',
                status: 'ongoing',
                total_budget: 'NGN10000000',
                actual_budget: 'NGN10000000',
                created_on: 'Nov 24, 2023',
                percentage_achieved: '25%',
            },
            {
                id: 3,
                budgetLine: 'Asset',
                name: 'Maintenance of truck',
                start_date: 'Nov 23, 2023',
                end_date: 'Nov 23, 2023',
                status: 'expired',
                total_budget: 'NGN10000000',
                actual_budget: 'NGN10000000',
                created_on: 'Nov 24, 2023',
                percentage_achieved: '25%',
            },
            {
                id: 4,
                budgetLine: 'Asset',
                name: 'Maintenance of truck',
                start_date: 'Nov 23, 2023',
                end_date: 'Nov 23, 2023',
                status: 'expired',
                total_budget: 'NGN10000000',
                actual_budget: 'NGN10000000',
                created_on: 'Nov 24, 2023',
                percentage_achieved: '25%',
            },
            {
                id: 5,
                budgetLine: 'Asset',
                name: 'Maintenance of truck',
                start_date: 'Nov 23, 2023',
                end_date: 'Nov 23, 2023',
                status: 'expired',
                total_budget: 'NGN10000000',
                actual_budget: 'NGN10000000',
                created_on: 'Nov 24, 2023',
                percentage_achieved: '25%',
            },
        ])

        const pillClass = (status) => {
            if (status === 'completed') {
                return 'budgetPill statusCompleted'
            } else if (status === 'expired') {
                return 'budgetPill statusExpired'
            } else {
                return 'budgetPill statusOngoing'
            }
        }
        return {
            budgetData,
            pillClass,
        }
    },
}
</script>
<style scoped>
.margin24 {
    margin-top: 24px;
}
.budgetPill {
    border-radius: 4px;
    border: 1px solid #d0d9ff;
    padding: 8px;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    text-transform: capitalize;
}
.statusCompleted {
    color: #06b04d;
}
.statusExpired {
    color: #fb2047;
}
.statusOngoing {
    color: #132c8c;
}
</style>
